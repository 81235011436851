import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { work } from '../../Utility/Work';
import { NavLink } from 'react-router-dom';

const Work = () => {
    // const handleSubmit = (e: any) => {
    //     e.preventDefault();
    // };

    return (
        <section className="section-work padding-tb-100" id="experience">
            <img src="assets/img/work/work-shape-1.png" alt="work-shape-1" className="work-shape-1" />
            <img src="assets/img/work/work-shape-2.png" alt="work-shape-2" className="work-shape-2" />
            <img src="assets/img/work/work-shape-3.png" alt="work-shape-3" className="work-shape-3" />
            <div className="container">
                <div className="row">
                    <Fade triggerOnce duration={1000} direction='up' className="col-lg-6 col-sm-12">
                        <img src="assets/img/work/featured1/two-tilted.png" alt="work" className="work-image" />
                    </Fade>
                    <Fade triggerOnce duration={1500} direction='up' className="col-lg-6 col-sm-12">
                        <div className="zx-work">
                            <div className="zx-app-banner w-100">
                                <h4>EXPERIENCE</h4>
                                <h2>Our process</h2>
                            </div>
                            <div className="container-fulid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="zx-app-work d-flex flex-column">
                                            {
                                                work.map((data, index) => (
                                                    <div key={index} className="zx-app-profile">
                                                        <div className="profile-image">
                                                            {data.image &&
                                                                <img src={data.image} alt="cloud-computing" />
                                                            }
                                                            {data.faIcon && 
                                                                <>{data.faIcon}</>
                                                            }
                                                        </div>
                                                        <div className="zx-detail">
                                                            <h5>{data.title}</h5>
                                                            <div>
                                                                {data.description}
                                                            </div>
                                                            {data.readMoreLink &&
                                                                <div className="active-read">
                                                                    <NavLink to={data.readMoreLink}>Read more <i className="fa-solid fa-arrow-right-long"></i></NavLink>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default Work