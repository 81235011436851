import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicroscope, faScrewdriverWrench, faMagnifyingGlass, faListCheck, faUpload } from '@fortawesome/free-solid-svg-icons';

export const work = [
    {
        title: 'Plan',
        description: <ul>
                        <li>Scope the app functionality</li>
                        <li>Wireframing</li>
                        <li>UI design (optional)</li>
                    </ul>,
        image: '',
        faIcon: <FontAwesomeIcon icon={faListCheck} fontSize={35} />,
        readMoreLink: ''
    },
    {
        title: 'Build',
        description: <ul>
                        <li>Functional development</li>
                        <li>Front-end build</li>
                        <li>Back-end services</li>
                        <li>3rd party integrations</li>
                    </ul>,
        image: '',
        faIcon: <FontAwesomeIcon icon={faScrewdriverWrench} fontSize={35} />,
        readMoreLink: ''
    }, 
    {
        title: 'Test',
        description: <ul>
                        <li>QA test with emulator and real devices</li>
                        <li>User acceptance test</li>
                    </ul>,
        image: '',
        faIcon: <FontAwesomeIcon icon={faMicroscope} fontSize={35} />,
        readMoreLink: ''
    },
    {
        title: 'Review',
        description: <ul>
                        <li>Prepare for store submission (icons, screenshots, description)</li>
                        <li>Submit to Apple App Store and Google Play Store for review</li>
                    </ul>,
        image: '',
        faIcon: <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={35} />,
        readMoreLink: ''
    },
    {
        title: 'Publish',
        description: <ul>
                        <li>Publish the app for your customers to enjoy</li>
                    </ul>,
        image: '',
        faIcon: <FontAwesomeIcon icon={faUpload} fontSize={35} />,
        readMoreLink: ''
    }
]