import React, { useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { faq } from '../../Utility/Faq';

const Faq = () => {
    const [isOpen, setIsOpen] = useState<number | null>(null);

    const toggleCollapse = (index: number) => {
        setIsOpen(isOpen === index ? null : index);
    };

    return (
        <section className="section-faq padding-tb-100 bg-market" id="faq">
            <div className="container">
                <div className="row">
                    <Fade triggerOnce duration={1000} direction='up' className="col-lg-6 col-sm-12" >
                        <div className="faq-image-border d-flex justify-content-center">
                            <img src="assets/img/faq/featured1/2.png" alt="" className="faq-image" />
                        </div>
                    </Fade>
                    <Fade triggerOnce duration={1500} direction='up' className="col-lg-6 col-sm-12" >
                        <>
                            <div className="zx-app-banner">
                                <h4>F.A.Q.</h4>
                                <h2>Frequently Asked Questions</h2>
                            </div>
                            {/*<p>
                                Questions
                            </p>*/}
                            <div className="accordion" id="accordionExample">
                                {faq.map((data, index) => (
                                    <div key={index} className="accordion-item">
                                        <h2 className="accordion-header" id={`heading${index}`}>
                                            <button
                                                onClick={() => toggleCollapse(index)}
                                                className={`accordion-button ${isOpen === index ? '' : 'collapsed'}`}
                                                type="button"
                                                aria-expanded={isOpen === index ? 'true' : 'false'}
                                                aria-controls={`collapse${index}`}
                                            >
                                                {data.question}
                                                <div className="box"></div>
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${index}`}
                                            className={`accordion-collapse collapse ${isOpen === index ? 'show' : ''}`}
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>{data.ans}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    </Fade>
                </div>
            </div>
        </section>
    )
}

export default Faq