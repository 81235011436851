import React from 'react'
import './SilverideaLogo.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';

const SilverideaLogo = () => {
  return (
    <div className='d-flex align-items-center'>
        <FontAwesomeIcon icon={faLightbulb} className='silveridea-icon' />
        <div className='silveridea-logo-text'>silveridea</div>
    </div>
  )
}

export default SilverideaLogo;