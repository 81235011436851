export const theme = [
    {
        color: "yellow-and-white"
    },
    {
        color: "green-and-white"
    },
    {
        color: "Pattens-Violet-and-white"
    },
    {
        color: "Light-Grey-and-white"
    },
    {
        color: "LightCoral-and-white"
    },
    {
        color: "slvid"
    }
]