import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple, faReact } from '@fortawesome/free-brands-svg-icons';

export const bestServices = [
    {
        number: '01',
        title: 'Cross platform',
        description: 
            <div>
                Our mobile apps are built once and target both iOS and Android platforms. We use react native.
                <div style={{float:'right'}}>
                    <FontAwesomeIcon icon={faApple} />
                    &nbsp;
                    <FontAwesomeIcon icon={faAndroid} />
                    &nbsp;
                    <FontAwesomeIcon icon={faReact} />
                </div>
            </div>
    },
    {
        number: '02',
        title: "Reusable components",
        description: <div>Prebuilt components are included to save time and development costs.</div>
    }, {
        number: '03',
        title: 'Experienced team',
        description: <div>We are a small team of experienced professionals and are 100% based in Australia.</div>
    }, {
        number: '04',
        title: 'Maintenance & Support',
        description: <div>We offer ongoing support and updates.</div>
    },
]