import React, { useEffect, useState } from 'react'

const ScrollButton = () => {
    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        // This will execute once the component has mounted
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to ensure this effect only runs once

    const scrollToTop = (e: any) => {
        e.preventDefault()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    return (
        <>
            {showScrollButton && (
                <a onClick={scrollToTop} href='/' className="back-to-top result-placeholder">
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                </a>
            )}
        </>
    )
}

export default ScrollButton