import React from 'react'
import { Fade } from 'react-awesome-reveal';
import ScrollButton from '../Components/Button/ScrollButton';
import ColorTheme from '../Components/Theme/ColorTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const FooterTwo = () => {
    return (
        <>
            <ScrollButton />
            <footer className="padding-t-50" id="contact">
                <img src="assets/img/theme/footer-theme-1.png" alt="footer-theme" className="footer-theme-1" />
                <img src="assets/img/theme/footer-theme-3.png" alt="footer-theme" className="footer-theme-3" />
                <img src="assets/img/theme/footer-theme-4.png" alt="footer-theme" className="footer-theme-4" />
                {/*<img src="assets/img/theme/footer-theme-3.png" alt="footer-theme" className="footer-theme-5" /> */}
                <div className="container">
                    {/*
                    <Fade triggerOnce duration={1500} direction='up' className="row border-bottom">
                        <>
                            <div className="col-lg-6 col-sm-12">
                                <div className="zx-app-fotter-banner">
                                    <h2>Lets talk</h2>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="subscribe">
                                    <h4>Subscribe Our Newsletter</h4>
                                    <p>
                                        Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                        Explicabo, unde!
                                    </p>
                                </div>
                                <form className="zx-app-footer-control" role="search">
                                    <input className="form-control shadow-none" type="search" placeholder="Your email Address"
                                        aria-label="Search" />
                                    <span className="fa fa-paper-plane" aria-hidden="true"></span>
                                </form>
                            </div>
                        </>
                    </Fade>
                    */}
                    <div className="row padding-tb-50 spacing-991">
                        {/*
                        <Fade triggerOnce duration={1000} direction='up' className="col-lg-5 col-md-6 col-sm-12">
                            <>
                                <div className="zx-app-footer">
                                    <img src="assets/img/logo/logo.png" alt="logo" className="white-mode" />
                                    <img src="assets/img/logo/logo-footer.png" alt="footer" className="dark-mode" />
                                </div>
                                <ul className="footer-main">
                                    <li>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod
                                            eos enim pariatur officia minima odio corrupti quaerat ex ipsa
                                            consequuntur.
                                        </p>
                                    </li>
                                    <li>
                                        <a href="/"><img src="assets/img/logo/facebook.png" alt="facebook" /></a>
                                        <a href="/"><img src="assets/img/logo/twitter.png" alt="twitter" /></a>
                                        <a href="/"><img src="assets/img/logo/instagram.png" alt="instagram" /></a>
                                        <a href="/"><img src="assets/img/logo/linkedin.png" alt="linkedin" /></a>
                                    </li>
                                </ul>
                            </>
                        </Fade>*/}
                        <Fade triggerOnce duration={1500} direction='up' className="col-12">
                            <>
                                <ul className="footer-main">
                                    <li>
                                        <h4>Contact us at Silveridea</h4>
                                    </li>
                                    <li className="zx-footer-link">
                                        <h6>
                                            <a href="mailto:hello@silveridea.com.au"><FontAwesomeIcon icon={faEnvelope} />  hello@silveridea.com.au</a>
                                        </h6>
                                    </li>
                                    {/* <li className="zx-footer-link">
                                        <h6>
                                            <a href="tel:+61"><FontAwesomeIcon icon={faPhone} />  +61</a>
                                        </h6>
                                    </li> */}
                                </ul>
                            </>
                        </Fade>
                        {/*
                        <Fade triggerOnce duration={2000} direction='up' className="col-lg-4 col-sm-12">
                            <>
                                <ul className="footer-main">
                                    <li>
                                        <h4>Support & Downloads</h4>
                                    </li>
                                    <li>
                                        <p>
                                            Lorem ipsum dolor sit, amet consectetur elit. Veniam dolore
                                            nobis rerum accusantium?
                                        </p>
                                    </li>
                                    <li className="zx-app-image-store">
                                        <img src="assets/img/landing/app-store.png" alt="app-store" />
                                        <img src="assets/img/landing/google-pay.png" alt="google-pay" />
                                    </li>
                                </ul>
                            </>
                        </Fade>*/}
                    </div>
                    <div className="row">
                        <div className='col-1 border-top d-flex align-items-center'>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/silveridea">
                                <img src="assets/img/logo/linkedin.png" alt="linkedin" />
                            </a>
                        </div>
                        <div className="col-10 border-top">
                            <div className="zx-app-last-footer">
                                <p>© 2017 - 2024 |   Silveridea    |    ABN:95773864159</p>
                            </div>
                        </div>
                        <div className="col-1 border-top">
                           &nbsp;
                        </div>
                    </div>
                </div>
            </footer>
            <ColorTheme />
        </>
    )
}

export default FooterTwo